import { Component, ElementRef, inject, Inject, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CompleteRegistrationComponent } from './completeRegistration.component';
import { UserService } from 'src/app/services/user.service';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  constructor(private userService: UserService) {} 

  ngOnInit() {
    let b2cURL = this.buildRoute();
    window.location.href = b2cURL;
  }


  private buildRoute(): string {
      const url = new URL(
        `https://${environment['tenantName']}.b2clogin.com/${environment['tenantName']}.onmicrosoft.com/oauth2/v2.0/authorize`
      );
      url.searchParams.append('p', (sessionStorage.getItem('brokerNeedsActivation') && sessionStorage.getItem('brokerNeedsActivation') == 'true') ? environment['policyNameBrokerRegister'] : environment['policyNameBroker']);
      url.searchParams.append('client_id', environment['b2cClientId']);
      url.searchParams.append('nonce', 'defaultNonce');
      url.searchParams.append('redirect_uri', environment['redirectUrlBroker'] + 'completeRegistration');
      url.searchParams.append('scope', environment['consentScopes'] ? environment['consentScopes'] : 'openid');
      url.searchParams.append('response_type', 'id_token');

      return url.href;
    }

}
